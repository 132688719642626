<template>
  <StaticField v-if="readonly || allReadonly" :value="text" />
  <VSelect v-else :value="values" :items="options" :rules="checker" @change="onInput" outlined multiple dense hideDetails="auto" />
</template>

<script>
import { map, keyBy, get, find, join, filter, isEmpty, compact } from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField';
export default {
  name: 'SelectMultiple',
  components: {
    StaticField,
  },
  props: {
    value: { type: Array },
    options: { type: Array },
    readonly: { type: Boolean },
    required: { type: Boolean },
    rules: { type: Array, default: () => [] },
  },
  computed: {
    allReadonly() {
      return filter(this.value, { readonly: true }).length === get(this.value, 'length', 0);
    },
    values() {
      return map(this.value, 'value');
    },
    text() {
      return join(map(this.values, (value) => get(find(this.options, { value }), 'text')), '\n')
    },
    checker() {
      return [
        ...this.rules,
        () => (this.required && !isEmpty(compact(this.values))) || 'Обязательное поле',
      ]
    },
  },
  methods: {
    onInput(value) {
      const current = keyBy(this.value, 'value');
      this.$emit('input', map(value, (value) => get(current, value, { date: '', id: '', type: 'LIST', value})));
    },
  }
}
</script>

<style module lang="scss">
</style>
